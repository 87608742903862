import React, { useEffect, useState } from "react";
import "./App.css";
import { Button, Stack, styled } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import section2_1 from "./json/section2_1.json";
import section2_2 from "./json/section2_2.json";
import section2_3 from "./json/section2_3.json";
import section2_5 from "./json/section2_5.json";
import section4 from "./json/section4.json";
import section5 from "./json/section5.json";
import section6 from "./json/section6.json";
import section7 from "./json/section7.json";
import section8 from "./json/section8.json";

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "lowercase",
  width: "300px",
  "&:hover": {
    backgroundColor: "#434a57",
    boxShadow: "none",
  },
});

function App() {
  const wordList = section2_5;
  const randomIndex = () => Math.floor(Math.random() * wordList.length);
  const params = new URLSearchParams(window.location.search);

  const [goodAnswers, setGoodAnswers] = useState(0);
  const [badAnswers, setBadAnswers] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(randomIndex());
  const [questionWord, setQuestionWord] = useState("");
  const [mode, setMode] = useState(Number(params.get("mode")));

  const getWordParamName = () => (mode === 0 ? "englishWord" : "polishWord");
  const getWordQuestionParamName = () => (mode === 1 ? "englishWord" : "polishWord");
  const toggleMode = () => {
    setGoodAnswers(0);
    setBadAnswers(0);
    setMode(mode === 1 ? 0 : 1);
  };

  const getAnswers = (index: number) => {
    let filteredWordList = wordList.filter((item, itemIndex) => itemIndex !== index);
    let shuffledWordList = filteredWordList
      .map((item, itemIndex) => {
        return { word: item[getWordParamName()], isCorrect: false };
      })
      .sort(() => 0.5 - Math.random());
    let answers = shuffledWordList.slice(0, 4);
    answers.push({ word: wordList[index][getWordParamName()], isCorrect: true });
    return answers.sort(() => 0.5 - Math.random());
  };

  useEffect(() => {
    setQuestionIndex(randomIndex());
  }, [goodAnswers, badAnswers]);

  useEffect(() => {
    setQuestionWord(wordList[questionIndex][getWordQuestionParamName()]);
  }, [questionIndex, mode]);

  const onButtonClick = (isCorrect: boolean) => {
    if (isCorrect) {
      setGoodAnswers(goodAnswers + 1);
    } else {
      setBadAnswers(badAnswers + 1);
    }
  };

  return (
    <div className="App">
      <Grid2 container sx={{ width: "100%" }} className="App-header">
        <Grid2 xs={12} sx={{ paddingRight: "2%", maxWidth: "800px" }}>
          <Stack direction={"row"} justifyContent={"end"}>
            <Button sx={{ color: "white" }} onClick={toggleMode}>
              Przełącz na tryb {mode === 0 ? "polski" : "angielski"}
            </Button>
          </Stack>
        </Grid2>
        <Grid2 xs={12} className="App-content">
          <h3>
            Twój wynik to <span style={{ color: "green" }}>{goodAnswers}</span> : <span style={{ color: "red" }}>{badAnswers}</span>
          </h3>
          <h1 style={{ fontWeight: 200, color: "#BBB" }}>
            Słówko dla <span style={{ fontWeight: 800, color: "#FFF" }}>{questionWord}</span> to:
          </h1>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            {getAnswers(questionIndex).map((item, index) => (
              <li style={{ paddingBottom: "15px" }} key={index}>
                <BootstrapButton onClick={() => onButtonClick(item.isCorrect)} size={"large"} variant={"text"} color={"error"} sx={{ textTransform: "lowercase", color: "white" }}>
                  <h2>{item.word}</h2>
                </BootstrapButton>
              </li>
            ))}
          </ul>
        </Grid2>
      </Grid2>
    </div>
  );
}

export default App;
